// extracted by mini-css-extract-plugin
export var checkoutPanel = "buy-module--checkoutPanel--2XoXW";
export var productImages = "buy-module--productImages--HB1sj";
export var basePriceWrapper = "buy-module--basePriceWrapper--1EU1j";
export var shippingPriceWrapper = "buy-module--shippingPriceWrapper---F9RN";
export var descriptionPanel = "buy-module--descriptionPanel--1xyDH";
export var descMarkdownWrapper = "buy-module--descMarkdownWrapper--2cEss";
export var paypalForm = "buy-module--paypalForm--2Srvu";
export var basePriceLabel = "buy-module--basePriceLabel--3Cm6J";
export var switchLabel = "buy-module--switchLabel--1r9p_";
export var thumbstickLabel = "buy-module--thumbstickLabel--cNtBZ";
export var basePriceRow = "buy-module--basePriceRow--3Gn9l";
export var switchRow = "buy-module--switchRow--1M3Bt";
export var thumbstickRow = "buy-module--thumbstickRow--37fli";
export var topWrapper = "buy-module--topWrapper--Ro9CE";