import React, {useState, useRef} from "react"
import PropTypes from "prop-types"
//import useWindowSize from "./UseWindowSize.js"
import * as styles from "./productImageGallery.module.css"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import {IconContext} from "react-icons"
//import {MdChevronLeft, MdChevronRight} from "react-icons/md"
import {BsChevronCompactRight, BsChevronCompactLeft} from "react-icons/bs"


const ProductImageGallery = ({ images, width, height, style, className}) => {
    //const {width} = useWindowSize();
    const myRef = useRef(null);
    const [mainImage, setMainImage] = useState(images.length > 0 ? images[0] : {src: null, alt: ""})

    const getMainImage = () => {
        return mainImage.src
    }

    const scroll = (amount) => {
        myRef.current.scrollLeft += amount;
    }
    /*const showLeft = () => {
        return (myRef != null) && (myRef.current != null) ? myRef.current.scrollLeft > 0 : true;
    }

    const showRight = () => {
        return (myRef != null) && (myRef.current != null) ? myRef.current.scrollLeft < (myRef.current.scrollWidth - myRef.current.clientWidth) : true;
    }*/

    return (
    <div className={(className ? (`${styles.wrapper} ${className}`) : styles.wrapper)} style={style}>
        <div className={styles.mainImageWrapper} style={{gridArea: "1/1/2/2", maxWidth: "100%"}} >
            <GatsbyImage 
                image={getImage(getMainImage())}
                alt=""
                objectFit="contain"
                style={{height: "100%", width: "100%"}}
                className={styles.mainImage}
            />
        </div>
        <div className={styles.thumbnailBar} style={{gridArea: "2/1/3/2"}}>
            <IconContext.Provider value={{ style: {verticalAlign: "middle"}, className: styles.thumbChevron, size: "50px"}}>
                <div onClick={()=> scroll(-80)} onKeyPress={()=>scroll(-20)} style={{gridArea: "1/1/2/2", margin: "auto auto auto 0", zIndex: "1"}}>
                    <BsChevronCompactLeft />
                </div>
            </IconContext.Provider>
            <IconContext.Provider value={{style: {verticalAlign: "middle"}, className: styles.thumbChevron, size: "50px"}}>
                <div onClick={() => scroll(80)} onKeyPress={()=> scroll(20)} style={{gridArea: "1/2/2/3", margin: "auto 0 auto auto", zIndex: "1"}}>
                    <BsChevronCompactRight />
                </div>
            </IconContext.Provider>
            <div ref={myRef} className={styles.flexContainer} style={{gridArea: "1/1/2/3"}}>
            {
                images.map( (img, idx) => {
                    return(
                        <div key={idx} className={styles.thumbnailWrapper} onClick={() => (setMainImage(img))} onKeyPress={()=>(setMainImage(img))}>
                            <GatsbyImage
                                image={getImage(img.src)}
                                alt={img.alt}
                                objectFit="contain"
                                style={{height: "100%"}}
                            />
                        </div>)
                })
            }
            </div>
            
        </div>
    </div>
    )
}

ProductImageGallery.propTypes = {
    images: PropTypes.array,
}
  
export default ProductImageGallery;
