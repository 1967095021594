import React from "react"
import { link, graphql } from "gatsby"
import * as styles from "./buy.module.css"
import Seo from "../components/seo"
import Layout from "../components/layout"
import ProductImageGallery from "../components/productImageGallery"
import useWindowSize from "../components/UseWindowSize"
import ExpandableBlock from "../components/expandableBlock"

const Buy = ({ data }) => {
    const { width } = useWindowSize();
    const TEST = false;
    return (
        <Layout>
            <Seo title="Buy" description="The last controller you'll buy - Get the ultimate 3D Printed controller!" />
            <div className={styles.topWrapper} style={{ display: "grid"}}>
                    <ProductImageGallery
                        style={{gridArea: "1/1/2/2"}}
                        className={styles.productImages}
                        images={[
                        {src: data.top_3_jpg, alt: "top view"},
                        {src: data.accessories_3_jpg, alt: "accessories"},
                        {src: data.granite_1_jpg, alt: "alt view"},
                        {src: data.keys_1_jpg, alt: "detailed view of keys"},
                        //{src: data.sticks_3_jpg, alt: "thumbstick options"},
                        {src: data.back_1_jpg, alt: "bottom view"},
                        {src: data.usb_1_jpg, alt: "detailed view of usb cable"},
                        {src: data.carbon_1_jpg, alt: "alt view"},
                        {src: data.angled_1_jpg, alt: "angled view"},
                        {src: data.dustcover_1_jpg, alt: "dust covers"},
                        {src: data.top_1_jpg, alt: "vertical view"},
                        {src: data.cre3d_electronics_view.gatsbyImageData, alt: "View of Controller Electronics" },
                        {src: data.mcu_removed_jpg, alt: "view of mcu removed"},
                        {src: data.brass_img.gatsbyImageData, alt: ""},
                        {src: data.cre3d_top_view.gatsbyImageData, alt: "Main Photo of Controller" },
                        {src: data.cre3d_side_view2.gatsbyImageData, alt: "Secondary Side View of Controller" },
                        {src: data.mech_switches_img.gatsbyImageData, alt: ""},
                        {src: data.assembly_img.gatsbyImageData, alt: ""}
                    
                    
                    ]}
                    />
            
                <div className={styles.checkoutPanel}>
                    <h2>CRE3D Controller V1</h2>
                    <div className={styles.basePriceWrapper}>
                        <span>$140.00 USD</span>
                    </div>
                    <div className={styles.shippingPriceWrapper}>
                        <span>Shipping: </span>
                        <span>$10.00 USD (Contiguous U.S. Only)</span>
                    </div>
                    <p style={{fontSize: ".85em", lineHeight: "1.1em"}}>Est. delivery time is 4-6 weeks but can be up to 90 days</p>
                    {/*<p style={{fontSize: ".85em", lineHeight: "1.1em"}}>Suspected fraudulent orders will be cancelled.</p>*/}
                    <p style={{fontSize: ".85em", lineHeight: "1.1em"}}>By ordering you agree to the "Terms and Conditions" below.</p>
                    <p style={{fontSize: ".85em", lineHeight: "1.1em"}}>* Product and included accessories may differ from images. Please read the description below for up-to-date details.</p>
                    {!TEST && (
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top" className={styles.paypalForm}>
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value="HVLLSANAM5M3Y" />
                        <table>
                            <tbody>
                            <tr className={styles.basePriceLabel}><td><input type="hidden" name="on0" value="Extra Main PCBs" />Extra Main PCBs</td></tr>
                            <tr className={styles.basePriceRow}><td><select name="os0">
                                <option value="Base">Base $140.00 USD</option>
                                <option value="+1 Extra PCB">+1 Extra PCB $147.50 USD</option>
                                <option value="+2 Extra PCB">+2 Extra PCB $155.00 USD</option>
                            </select> </td></tr>
                            <tr className={styles.switchLabel}><td><input type="hidden" name="on1" value="Switch Type" />Switch Type</td></tr>
                            <tr className={styles.switchRow}><td><select name="os1">
                                <option value="Kailh Speed Silver">Kailh Speed Silver </option>
                                <option value="Kailh Speed Bronze">Kailh Speed Bronze </option>
                                <option value="Kailh Speed Copper">Kailh Speed Copper </option>
                            </select> </td></tr>
                            <tr className={styles.thumbstickLabel}><td><input type="hidden" name="on2" value="Thumbstick Color" />Thumbstick Color</td></tr>
                            <tr className={styles.thumbstickRow}><td><select name="os2">
	                            <option value="Black">Black </option>
	                            <option value="White">White </option>
                            </select> </td></tr>
                            </tbody>
                        </table>
                        <input type="hidden" name="currency_code" value="USD" />
                        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
                        <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                    </form>)}



                    {TEST && (
                    <div>
                    <p>** TEST ONLY **</p>
                    <form action="https://www.sandbox.paypal.com/cgi-bin/webscr" method="post" target="_top" className={styles.paypalForm}>
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value="BL9WHBLVF95XA" />
                        <table>
                            <tr className={styles.basePriceRow}><td><input type="hidden" name="on0" value="Extra PCBs" />Extra PCBs</td></tr><tr><td><select name="os0">
                                <option value="Base">Base $150.00 USD</option>
                                <option value="1 extra PCB (+15)">1 extra PCB (+15) $165.00 USD</option>
                                <option value="2 extra PCB (+25)">2 extra PCB (+25) $175.00 USD</option>
                            </select> </td></tr>
                            <tr className={styles.switchRow}><td><input type="hidden" name="on1" value="Switches" />Switches</td></tr><tr><td><select name="os1">
                                <option value="Kailh Speed Silver">Kailh Speed Silver </option>
                                <option value="Kailh Speed Copper">Kailh Speed Copper </option>
                                <option value="Kailh Speed Bronze">Kailh Speed Bronze </option>
                            </select> </td></tr>
                            <tr><td><input type="hidden" name="on2" value="Color (Orange/Black, Black)" />Color (Orange/Black, Black)</td></tr><tr><td><input type="text" name="os2" maxlength="200" /></td></tr>
                        </table>
                        <input type="hidden" name="currency_code" value="USD" />
                        <input type="image" src="https://www.sandbox.paypal.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
                        <img alt="" border="0" src="https://www.sandbox.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                    </form>
                    </div>
                    )}

                    <p>For custom orders, contact me via Email or Twitter</p>


                </div>
            </div>
            <div className={styles.descriptionPanel}>
                <div className={styles.descMarkdownWrapper}>
                    <h2>What's Included</h2>
                    <ul>
                        <li>CRE3D Controller V1</li>
                        <li>6' USB Micro 2.0 Cable</li>
                        <li>2x Dust Covers</li>
                        <li>M2 Hex Key</li>
                        <li>2x Key Faceplates</li>
                        <li>2x Finger Rests</li>
                        <li>2x Xbox One Style Thumbsticks*</li>
                    </ul>
                    <p>* Note on Thumbsticks: 3D printed thumbsticks of any height are available as a custom order but mass produced ones will be the standard option going forward.</p>
                    <ExpandableBlock head="Variable Options" defaultState={true}>
                            <h3>Mechanical Switches</h3>
                            <p>There are 3 standard switch options:</p>
                            <ol>
                                <li>Kailh Speed Silver (Linear, 40g, 1.1mm actuation, 1.4mm reset, 3.5mm total travel)</li>
                                <li>Kailh Speed Bronze (Clicky, 50g, 1.1mm actuation, 1.2mm reset, 3.5mm total travel)</li>
                                <li>Kailh Speed Copper (Tactile, 40g, 1.1mm actuation, 1.2mm reset, 3.5mm total travel)</li>
                            </ol>
                            <p>Custom switch options are available on request but will incur additional costs which you will be billed for after placing your initial order.</p>
                            <h3>Analog Stick Tension</h3>
                            <p>Custom tensioned analog sticks are available at request through Battle Beaver Customs or another supplier of your choosing. This will incur additional costs and billing depending on the cost of part acquisition.</p>
                            <p>I am also hoping to have variable tension sticks as used in the Elite controllers in 1-2 months.</p>
                            <h3>Colors</h3>
                            <p>The controllers currently will ship with a black base color and orange accents with translucent grey keycaps. I may offer a variety of colors in the future. Specific color combinations are available at request for an additional cost.</p>
                            <h3>Extra PCBs/Parts</h3>
                            <p>You may choose to order additional main PCBs as spares. You will be able to order them separately in the near future</p>
                            <p>For any at-request or non-standard orders, please contact me before placing your order so that I can ensure I am able to fulfill whatever your requests are.</p>
                    </ExpandableBlock>
                    
                    <ExpandableBlock head="Sizing" defaultState={false}>
                        <p>This controller is not suitable for children or individuals with small hands. The minimum recommended hand size is around 16.5cm by 9cm. For how to measure your hand size see <a style={{color: "white", textDecoration: "underline"}}href="https://www2.razer.com/gaming-mice-find-the-right-fit">this guide by Razer</a>. It is the buyers responsibility to ensure they are capable of using the controller and sizing issues will not warrant refunds or returns. To help with making this determination you can follow the instructions for creating a sizing template <a style={{color: "white", textDecoration: "underline"}} href="/sizing_guide">here</a>.</p>
                    </ExpandableBlock>
                    <ExpandableBlock head="Caring For Your Controller" defaultState={false}>
                        <div style={{marginLeft: "1em"}}>
                            <h3>Heat Sensitivity</h3>
                            <p>The controllers are 3D printed in PLA which has a glass transition (melting) temperature compared to typical household plastics like ABS. In the presence of temperatures in excess of 110f, the controller may become soft and malleable or deform. Avoid leaving your controller in direct sunlight on hot days, in a hot car, next to space heaters, candles, or the hot exhaust of a computer. If you must store your controller in one of these environments, I recommend putting it inside a box, drawer or bag so that it is not directly exposed to the heat.</p>
                            <h3>Water and Liquid Damage</h3>
                            <p>The controllers contain electronics that can be damaged by water or other liquids. Avoid the controller coming in contact with water. If you happen to spill water on or inside your controller, you should immediately unplug it from any computer or power source, disassemble the controller and place the electronics in rice or desiccant for several days.</p>
                            <h3>Drops, Scratches and Physical Damage</h3>
                            <p>PLA is a relatively strong material that is great for 3D printing but is typically less durable than traditional household plastics. Avoid throwing the controller, dropping the controller or the controller coming in contact with abrasive surfaces. The controller should last a long time when treated with care.</p>
                            <h3>Damage During Disassembly/Reassembly</h3>
                            <p>The controller is designed to be disassembled and re-assembled but some care is needed to avoid damage. Please refer to the guides or instruction manual for tips and best practices on disassembly and reassembly.</p>
                        </div>
                    </ExpandableBlock>
                    <ExpandableBlock head="A Note About 3D Printed Products" defaultState={false}>
                        <p>The controllers are 3D printed and hand assembled. Due to the nature of 3D printing there may be some variance or imperfections in the product you receive compared to images shared on social media or this website. While I strive to achieve a high level of consistency and quality, it will not be on the same level of plastic products most consumers are used to. This is limited to any inconsistencies or blemishes not affecting performance or functionality. If you receive your controller and it has issues with functionality, you may contact me for help troubleshooting. All controllers are inspected and fully tested for functionality prior to shipping.</p>
                    </ExpandableBlock>
                    <ExpandableBlock head="Shipping and Est. Delivery" defaultState={false}>
                        <h2>Estimated Delivery</h2>
                        <p>Controllers are made to order and there may sometimes be delays due to sourcing specific parts for an order. I hope to ship most controllers within 4 weeks of receipt. If after 90 days, the order has still not been shipped I will issue a full refund. The 90 day limit does not apply to custom orders. If you have questions about the status of your order you can check Twitter for updates or contact me directly.</p>
                        <h2>Shipping</h2>
                        <p>Shipping only to the 48 contiguous United States. If your address changes while your controller is being built please contact me immediately. Orders are shipped using whatever is most economical at the time (typically USPS). Any orders placed with addresses not in the contiguous U.S. will be cancelled. I do not offer international shipping but hope to do so in the future.</p>
                        
                    </ExpandableBlock>
                    <ExpandableBlock head="Returns, Refunds and Cancellations" defaultState={false}>
                        <p>Orders are not cancellable. I do not accept returns or issue refunds for any reason. Exceptions may be made on a case by case basis at my sole discretion. If you have an issue, please contact me directly via email.</p>   
                    </ExpandableBlock>
                    <ExpandableBlock head="Terms and Conditions" defaultState={false}>
                        <ol>
                            <li>
                                <p>The buyer understands that the product(s) in question is 3d printed and hand assembled and as such there may be some variance in the look and feel of the product(s) they receive.</p>
                            </li>
                            <li>
                                <p>The buyer understands that product(s) are made to order and may take some time to complete. The seller has up to 90 days to provide shipping confirmation unless otherwise notified. After 90 days, if no shipping confirmation has been provided, the buyer may request a full refund. The seller will not be liable for any losses, damages, penalties, or expenses for failure to meet any delivery date.</p>
                            </li>
                            <li>
                                <p>The seller reserves the right to cancel the order at any time and refund the full amount paid at the time of purchase.</p>
                            </li>
                            <li>
                                <p>The buyer understands that there will be no refunds for any reason except in the cases mentioned above. The buyer understands that the seller is not liable for any damages due to shipping and such damages should be taken up with the shipping provider used. The buyer agrees the seller is not responsible for delays in shipping and that the 90 days mentioned in (2) does not apply once proof of shipment or exchange has been provided.</p>
                            </li>
                            <li>
                                <p>The Goods are being sold “as is,” and the Seller disclaims all warranties of quality, whether express or implied, including the warranties of merchantability and fitness for particular purpose. The Buyer acknowledges that it has not been induced by any statements or representations of any person with respect to the quality or condition of the Goods and that no such statements or representations have been made. The Buyer acknowledges that it has relied solely on the investigations, examinations, and inspections as the Buyer has chosen to make and that the Seller has afforded the Buyer the opportunity for full and complete investigations, examinations, and inspections.</p>
                            </li>
                            <li>
                                <p>The buyer agrees they are responsible for paying all taxes, duties and other governmental charges in connection with the sale, purchase, delivery and use of the goods.</p>
                            </li>
                            <li>
                                <p>The buyer understands that once an order is placed, no further changes may be made to modify the order. If there is an issue, please contact the seller. Any changes will be made at the sole discretion of the seller. This includes that no cancellations can be made.</p>
                            </li>
                            <li>
                                <p>The buyer understands that the seller is not liable for any damages to their computer or other electronic devices as a result of connecting the product(s) to their device. The buyer understands that due-diligence is on the buyer to operate the device safely and any guides for modification or updates are provided without warranty, with the full liability on the buyer to not damaging the product(s) and/or their devices. The seller is not liable for any injuries or damages related to modifying the product(s) in any way, whether by a seller-provided guide or not.</p>
                            </li>
                            <li>
                                <p>The buyer understands that the seller is not responsible for any losses, damages, penalties or termination of account(s) incurred as a result of using the product(s) in any video game. The buyer understands that the product(s) may be against the TOS (Terms of Service) of some online video games and the responsibility is on the buyer to ensure that the device conforms to the TOS of any online video game with which the product(s) are used. The buyer agrees to abide by the TOS in any online video game with which they use the product(s) and to not implement or utilize any features (such as macros) which may be against the terms of said game.</p>
                            </li>
                            <li>
                                <p>The Buyer may not assign any of its rights under this agreement or delegate any performance under this agreement, except with the prior written consent of the Seller. Any purported assignment of rights or delegation of performance in violation of this section is void.</p>
                            </li>
                            <li>
                                <p>The buyer agrees to be contacted by the seller about the details or logistics of their order via email</p>
                            </li>
                        </ol>
                    </ExpandableBlock>
                </div>
            </div>
        </Layout>

    )
}
export const query = graphql`
  query ProductImageQuery{
    accessories_3_jpg: imageSharp(resize: {originalName: {eq: "accessories_3.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    top_3_jpg: imageSharp(resize: {originalName: {eq: "top_3.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    keys_1_jpg: imageSharp(resize: {originalName: {eq: "keys_1.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    usb_1_jpg: imageSharp(resize: {originalName: {eq: "usb_1.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    back_1_jpg: imageSharp(resize: {originalName: {eq: "back_1.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    granite_1_jpg: imageSharp(resize: {originalName: {eq: "granite_1.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    top_1_jpg: imageSharp(resize: {originalName: {eq: "top_1.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    dustcover_1_jpg: imageSharp(resize: {originalName: {eq: "dustcover_1.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    carbon_1_jpg: imageSharp(resize: {originalName: {eq: "carbon_1.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    angled_1_jpg: imageSharp(resize: {originalName: {eq: "angled_1.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    

    mcu_removed_jpg: imageSharp(resize: {originalName: {eq: "mcu_removed.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    cre3d_electronics_view: imageSharp(resize: {originalName: {eq: "cre3d_electronics_top.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    cre3d_side_view2: imageSharp(resize: {originalName: {eq: "cre3d_side_view2.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    cre3d_top_view: imageSharp(resize: {originalName: {eq: "cre3d_top_view.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    brass_img: imageSharp(resize: {originalName: {eq: "brass_inserts.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    mech_switches_img: imageSharp(resize: {originalName: {eq: "mech_switches.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    assembly_img: imageSharp(resize: {originalName: {eq: "assembly_wide_wm.jpg"}}) {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, JPG, WEBP, AVIF])
    }
    description_md: markdownRemark(fileAbsolutePath: {regex: "//src/pages/BuyPageTerms.md/"}) {
        frontmatter {
        title
        }
        html
    }
}`
export default Buy;
