import React, {useState} from "react"

import PropTypes from "prop-types"
//import useWindowSize from "./UseWindowSize.js"
import {IconContext} from "react-icons"
import {MdExpandMore, MdExpandLess} from "react-icons/md"


const ExpandableBlock = ({ head, children, defaultState, maxHead}) => {
    //const {width} = useWindowSize();
    const [expanded, setExpanded] = useState(defaultState ? true : false)
    return (
        <div style={{width: (maxHead ? maxHead : "100%"), display: "grid"}}>
            <div style={{width: "100%", 
                        gridArea: "1/1/2/2", 
                        border: "1px solid white", 
                        textAlign: "left", 
                        display: "flex",
                        fontSize: "1.5em",
                        lineHeight: "1em",
                        padding: "1em"}}
                 onClick={()=>setExpanded(!expanded)}
                 onKeyPress={()=>setExpanded(!expanded)}>
                <span>{head}</span>
                <IconContext.Provider value={{style:{verticalAlign: "middle", marginLeft: "auto"}}}>
                    {expanded ? (<MdExpandLess />) : (<MdExpandMore />)}
                </IconContext.Provider>
            </div>
            <div style={{gridArea: "2/1/3/2",
                        padding: (expanded ? "1em" : "0"),
                        overflow: "hidden",
                        boxSizing: "border-box",
                        height: (expanded ? "min-content" : "0")}}>
                {children}
            </div>
        </div>
    )
}

ExpandableBlock.propTypes = {
    defaultState: PropTypes.bool,
    head: PropTypes.string,
}
export default ExpandableBlock;